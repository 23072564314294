var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"98%","icon":"mdi-cellphone-dock","color":"primary","title":"Audiencias","text":"Listado audiencias"}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.openForm}},on),[_vm._v(" Crear audiencia ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-update")]),_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])],1),_c('v-divider'),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.editedIndex == -1),expression:"editedIndex == -1"}],ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('div',{staticClass:"pa-5 ma-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("ID administrador de anuncios")]),_c('VTextFieldWithValidation',{attrs:{"disabled":"","rules":"required","label":"Coloca el nombre de la audiencia"},model:{value:(_vm.adManagerId),callback:function ($$v) {_vm.adManagerId=$$v},expression:"adManagerId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nombre")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Coloca el nombre de la audiencia"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Subtipo")]),_c('v-select',{attrs:{"hide-details":"","disabled":"","items":[
                        'CUSTOM',
                        'WEBSITE',
                        'APP',
                        'OFFLINE_CONVERSION',
                        'CLAIM',
                        'PARTNER',
                        'MANAGED',
                        'VIDEO',
                        'LOOKALIKE',
                        'ENGAGEMENT',
                        'BAG_OF_ACCOUNTS',
                        'STUDY_RULE_AUDIENCE',
                        'FOX',
                        'MEASUREMENT',
                        'REGULATED_CATEGORIES_AUDIENCE' ],"outlined":""},model:{value:(_vm.editedItem.subtype),callback:function ($$v) {_vm.$set(_vm.editedItem, "subtype", $$v)},expression:"editedItem.subtype"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Fuente de datos")]),_c('v-select',{attrs:{"disabled":"","hide-details":"","items":[
                        'USER_PROVIDED_ONLY',
                        'PARTNER_PROVIDED_ONLY',
                        'BOTH_USER_AND_PARTNER_PROVIDED' ],"outlined":""},model:{value:(_vm.editedItem.customer_file_source),callback:function ($$v) {_vm.$set(_vm.editedItem, "customer_file_source", $$v)},expression:"editedItem.customer_file_source"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Descripción")]),_c('v-textarea',{attrs:{"placeholder":"descripcion","outlined":""},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1)],1)],1),_c('v-card-actions',{attrs:{"rd-actions":""}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"outlined":"","color":"error","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"loading":_vm.loadingButton,"color":"success"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editedIndex !== -1),expression:"editedIndex !== -1"}],staticClass:"pa-5"},[_c('b',[_vm._v("Nombre de audiencia: ")]),_vm._v(_vm._s(_vm.editedItem.name)+" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-combobox',{staticClass:"mt-3",attrs:{"item-text":"name","search-input":_vm.searchLabel,"item-value":"_id","items":_vm.todofullLabels,"multiple":"","chips":"","no-data-text":"No se encontraron etiquetas"},on:{"update:searchInput":function($event){_vm.searchLabel=$event},"update:search-input":function($event){_vm.searchLabel=$event},"change":function($event){return _vm.updateLabels(_vm.editedItem.todofullLabels)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var attrs = ref.attrs;
                      var item = ref.item;
                      var select = ref.select;
                      var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","color":"primary"},on:{"click":select,"click:close":function($event){return _vm.removeLabels(_vm.editedItem.todofullLabels, item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.name))])])]}}]),model:{value:(_vm.editedItem.todofullLabels),callback:function ($$v) {_vm.$set(_vm.editedItem, "todofullLabels", $$v)},expression:"editedItem.todofullLabels"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-switch',{attrs:{"label":"Ver SOLO leads faltantes"},model:{value:(_vm.showMissingLeads),callback:function ($$v) {_vm.showMissingLeads=$$v},expression:"showMissingLeads"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.currentView = 'CleanLeads';
                      _vm.getLeadsByTodofullLabels(
                        _vm.editedItem.todofullLabels,
                        _vm.editedItem._id
                      );}}},[_vm._v("Ver Leads")])],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.currentView = 'Leads';
                      _vm.getPotentialLeadsByTodofullLabels(
                        _vm.editedItem.todofullLabels,
                        _vm.editedItem._id
                      );}}},[_vm._v("Ver potenciales Leads")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentView && _vm.currentView == 'CleanLeads'),expression:"currentView && currentView == 'CleanLeads'"}],attrs:{"color":"error"},on:{"click":function($event){return _vm.sendLeadsToAudience(
                        _vm.editedItem,
                        _vm.editedItem.todofullLabels
                      )}}},[_vm._v("Enviar a Audiencia")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentView && _vm.currentView == 'Leads'),expression:"currentView && currentView == 'Leads'"}],attrs:{"color":"error"},on:{"click":function($event){return _vm.sendPotentialLeadsToAudience(
                        _vm.editedItem,
                        _vm.editedItem.todofullLabels
                      )}}},[_vm._v("Enviar a Audiencia")])],1)],1),_c('v-row',{staticClass:"ma-1"},[_c('b',[_vm._v("Total leads: ")]),_vm._v(_vm._s(_vm.totalDocs)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('h6',[_vm._v("Primeros 10 leads del listado")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Última Actualización")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.currentView == "Leads" ? "ID Contacto" : "Teléfono")+" ")])])]),_c('tbody',_vm._l((_vm.cleanLeads),function(lead){return _c('tr',{key:lead._id},[_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(lead.updatedAt)))]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentView == 'Leads'),expression:"currentView == 'Leads'"}]},[_vm._v(" "+_vm._s(lead.contactId)+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentView == 'CleanLeads'),expression:"currentView == 'CleanLeads'"}]},[_vm._v(" "+_vm._s(lead.telefono)+" ")])])}),0)]},proxy:true}])})],1)],1),_c('v-card-actions',{attrs:{"rd-actions":""}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"outlined":"","color":"error","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"loading":_vm.loadingButton,"color":"success"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","hide-default-footer":"","headers":_vm.headers,"items":_vm.audiences,"items-per-page":100},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-account-multiple-plus")])],1),_c('v-btn',{attrs:{"color":"error","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.conditions",fn:function(ref){
                      var item = ref.item;
return [_c('v-checkbox',{attrs:{"label":"Con Datos","value":"Con Datos","multiple":""},on:{"change":function($event){return _vm.updatedConditions(item, $event)}},model:{value:(item.conditions),callback:function ($$v) {_vm.$set(item, "conditions", $$v)},expression:"item.conditions"}}),_c('v-checkbox',{attrs:{"label":"Sin Datos","value":"Sin Datos","multiple":""},on:{"change":function($event){return _vm.updatedConditions(item, $event)}},model:{value:(item.conditions),callback:function ($$v) {_vm.$set(item, "conditions", $$v)},expression:"item.conditions"}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }